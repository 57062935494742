"use client";

import React, { createContext, Reducer, useReducer, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { ButtonProps } from "@/components/ui/button";
import { type RequireKeys } from "@/lib/utils";
import { Input } from "@/components/ui/input";

const AlerterContext = createContext<{
  alert: (
    message: State["message"],
    options: RequireKeys<Partial<Omit<State, "open" | "message">>, "title">
  ) => void;
}>({
  alert: () => {
    throw new Error("AlerterContext not provided");
  },
});

type State = {
  open: boolean;
  title: string;
  message: React.ReactNode;
  /**
   * The value that the user must enter to confirm the action
   */
  confirmationValue?: string;
  confirmText?: string;
  cancelText: string;
  type: ButtonProps["variant"];
  onCancel?: () => void;
  onConfirm?: () => void;
};

type Actions = {
  type: "open" | "close";
  payload?: Partial<State>;
};

const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case "open":
      return { ...state, ...action.payload, open: true };
    case "close":
      return { ...state, open: false };
    default:
      return state;
  }
};

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alert, dispatch] = useReducer(reducer, {
    open: false,
    title: "",
    message: "",
    confirmText: undefined,
    cancelText: "Cancel",
    type: "secondary",
    confirmationValue: undefined,
  });
  const [confirmationValue, setConfirmationValue] = useState("");

  const openAlert = (
    message: string,
    options: Partial<Omit<State, "open" | "message">>
  ) => {
    dispatch({
      type: "open",
      payload: {
        message,
        ...options,
        confirmText: options.confirmText || undefined,
        cancelText: options.cancelText || "Cancel",
        type: options.type || "secondary",
        onConfirm: options.confirmText ? options.onConfirm : undefined,
        onCancel: options.onCancel || undefined,
      },
    });
  };

  const cancel = () => {
    setConfirmationValue("");
    dispatch({ type: "close" });
    alert.onCancel?.();
  };

  const confirm = () => {
    setConfirmationValue("");
    dispatch({ type: "close" });
    alert.onConfirm?.();
  };

  return (
    <AlerterContext.Provider value={{ alert: openAlert }}>
      {children}
      <AlertDialog
        open={alert.open}
        onOpenChange={(open) => dispatch({ type: open ? "open" : "close" })}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{alert.title}</AlertDialogTitle>
            <AlertDialogDescription>
              <>
                {alert.message}
                {alert.confirmationValue && (
                  <Input
                    type="text"
                    value={confirmationValue}
                    label="Application Name"
                    placeholder={alert.confirmationValue}
                    className="mt-4"
                    onChange={(e) => setConfirmationValue(e.target.value)}
                  />
                )}
              </>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={cancel}>
              {alert.cancelText}
            </AlertDialogCancel>
            {alert.confirmText && (
              <AlertDialogAction
                onClick={confirm}
                variant={alert.type}
                disabled={
                  alert.confirmationValue !== undefined &&
                  confirmationValue !== alert.confirmationValue
                }
              >
                {alert.confirmText}
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AlerterContext.Provider>
  );
};

export const useAlerter = () => {
  return React.useContext(AlerterContext).alert;
};
